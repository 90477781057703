import { render, staticRenderFns } from "./DetailList.vue?vue&type=template&id=62ecf02e&scoped=true&"
import script from "./DetailList.vue?vue&type=script&lang=js&"
export * from "./DetailList.vue?vue&type=script&lang=js&"
import style0 from "./DetailList.scss?vue&type=style&index=0&id=62ecf02e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ecf02e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCol,VRow})
