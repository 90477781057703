<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="taskLoaded ? '9' : '12'">
        <calendar
          ref="calendar"
          :month.sync="month"
          type="three-days"
          @event:click="displayTaskInfo"
        />
      </v-col>
      <template v-if="taskLoaded">
        <v-divider vertical></v-divider>
        <v-col cols="3" style="height: 90vh; overflow-y: scroll">
          <event-detail :task="task" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import Calendar from '@/components/admin/partials/Calendar/Calendar'
import EventDetail from '@/components/admin/partials/Calendar/Event/Detail'
import { mapGetters } from 'vuex'
export default {
  name: 'ThreeDays',
  components: { EventDetail, Calendar },
  computed: {
    ...mapGetters(['allTasks']),
    task() {
      return this.$store.getters.task
    },
    currentMonth() {
      return dayjs()
    }
  },
  data: () => ({
    month: dayjs(),
    taskLoaded: false
  }),
  methods: {
    async displayTaskInfo(e) {
      this.$refs.calendar.toggleLoading()
      await this.$store.dispatch('GET_TASK_BY_ID', e)
      this.$refs.calendar.toggleLoading()
      if (!this.taskLoaded) this.taskLoaded = true
    }
  }
}
</script>

<style src="./ThreeDays.scss" lang="scss" scoped></style>
