var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": _vm.taskLoaded ? '9' : '12'
    }
  }, [_c('calendar', {
    ref: "calendar",
    attrs: {
      "month": _vm.month,
      "type": "three-days"
    },
    on: {
      "update:month": function updateMonth($event) {
        _vm.month = $event;
      },
      "event:click": _vm.displayTaskInfo
    }
  })], 1), _vm.taskLoaded ? [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticStyle: {
      "height": "90vh",
      "overflow-y": "scroll"
    },
    attrs: {
      "cols": "3"
    }
  }, [_c('event-detail', {
    attrs: {
      "task": _vm.task
    }
  })], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }