var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-3",
    attrs: {
      "bind": _vm.$attrs
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "event-detail-title d-flex align-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#3C346E",
      "size": "25"
    }
  }, [_vm._v(" $note ")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("現場")])], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "new-link",
    attrs: {
      "to": {
        name: 'TaskView',
        params: {
          id: _vm.task.id
        }
      },
      "target": "_blank",
      "rounded": ""
    }
  }, [_vm._v(" 詳細 "), _c('v-icon', {
    attrs: {
      "right": "",
      "color": "#444444",
      "size": "20"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1)], 1), _c('h3', {
    staticClass: "mt-4 event-detail-customer"
  }, [_vm._v(_vm._s(_vm.task.customer.name) + " 様")]), _c('h4', {
    staticClass: "mt-4 event-detail-customer"
  }, [_vm._v(" " + _vm._s(_vm.task.site.name) + " ")]), _c('div', {
    staticClass: "mb-3 event-detail-time"
  }, [_c('div', {
    staticClass: "py-1",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.openAddressInGoogleMaps();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("$location")]), _c('span', {
    staticClass: "address-txt"
  }, [_vm._v(" " + _vm._s(_vm.siteAddress) + " ")]), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "size": "18"
    }
  }, [_vm._v("$newTab")])], 1), _c('div', {
    staticClass: "py-1"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("$workTime")]), _c('span', {
    staticClass: "meeting-txt"
  }, [_vm._v("集合")]), _c('span', {
    staticClass: "ml-3 event-detail-time-text"
  }, [_vm._v(" " + _vm._s(_vm.task.gathering_time) + " ")])], 1), _c('div', {
    staticClass: "py-1"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("$workTime")]), _c('span', {
    staticClass: "start-txt"
  }, [_vm._v("開始")]), _c('span', {
    staticClass: "ml-3 event-detail-time-text"
  }, [_vm._v(" " + _vm._s(_vm.task.task_start_time) + " ")])], 1), _vm.task.car ? _c('div', {
    staticClass: "py-1"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("$car")]), _c('span', {
    staticClass: "car-info"
  }, [_vm._v(" " + _vm._s(_vm.task.car.car_class) + " " + _vm._s(_vm.task.car.number_plate) + " ")])], 1) : _vm._e()]), _vm.hasPartnerData ? _c('div', {
    staticClass: "info-box pa-3 mb-6"
  }, [_c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_vm.project.partner_0 ? [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-chip', {
    staticClass: "label d-block text-center",
    attrs: {
      "x-small": "",
      "label": "",
      "text-color": "white",
      "color": "#666666"
    }
  }, [_vm._v(" メーカー ")])], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium info-txt"
  }, [_vm._v(" " + _vm._s(_vm.project.partner_0.name || '-') + " ")])])] : _vm._e(), _vm.project.partner_1 ? [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-chip', {
    staticClass: "label d-block text-center",
    attrs: {
      "x-small": "",
      "label": "",
      "text-color": "white",
      "color": "#666666"
    }
  }, [_vm._v(" 発注 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium info-txt"
  }, [_vm._v(" " + _vm._s(_vm.project.partner_1.name || '-') + " ")])])] : _vm._e(), _vm.project.partner_2 ? [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-chip', {
    staticClass: "label d-block text-center",
    attrs: {
      "x-small": "",
      "label": "",
      "text-color": "white",
      "color": "#666666"
    }
  }, [_vm._v(" 元請 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium info-txt"
  }, [_vm._v(" " + _vm._s(_vm.project.partner_2.name || '-') + " ")])])] : _vm._e()], 2)], 1) : _vm._e(), _c('v-divider'), _c('v-row', {
    staticClass: "mt-6 mb-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "event-detail-title d-flex align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#3C346E",
      "size": "25"
    }
  }, [_vm._v(" $users ")]), _c('div', {
    staticClass: "ml-2"
  }, [_vm._v(" 参加者"), _c('span', {
    staticClass: "ml-2 people"
  }, [_vm._v(_vm._s(_vm.task.task_member.length) + "人")])])], 1)], 1), _c('div', {
    staticClass: "mb-4"
  }, _vm._l(_vm.task.task_member, function (member, memberIndex) {
    return _c('member-list', {
      key: "member-".concat(memberIndex + 1),
      attrs: {
        "name": member.full_name,
        "leader": _vm.isLeader(member),
        "photo": member.profile_image_url
      }
    });
  }), 1), _c('v-divider'), _c('v-row', {
    staticClass: "mt-6 mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "event-detail-title d-flex align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#3C346E",
      "size": "25"
    }
  }, [_vm._v(" $work ")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("内容")])], 1)], 1), _c('div', {
    staticClass: "event-detail-task-title mb-4 font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.task.service.name) + " ")]), _vm.hasMemo ? [_c('v-divider'), _c('v-row', {
    staticClass: "mt-6 mb-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "event-detail-title",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#3C346E",
      "size": "25"
    }
  }, [_vm._v(" $info ")]), _vm._v(" メモ ")], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-chip', {
    staticClass: "event-detail-warning-label mb-2",
    attrs: {
      "text-color": "white",
      "color": "#B00101",
      "label": "",
      "small": ""
    }
  }, [_vm._v(" 重要連絡 ")]), _c('div', {
    staticClass: "event-detail-warning-box"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm._v(" " + _vm._s(_vm.task.important_notice ? _vm.task.important_notice : '-') + " ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 通常メモ "), _c('div', {
    staticClass: "event-detail-memo-box mt-2"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm._v(" " + _vm._s(_vm.task.memo ? _vm.task.memo : '-') + " ")])])])], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }