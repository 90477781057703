<template>
  <div class="pa-3" :bind="$attrs">
    <v-row align="center" no-gutters>
      <v-col cols="6" class="event-detail-title d-flex align-center">
        <v-icon color="#3C346E" size="25">
          $note
        </v-icon>
        <span class="ml-2">現場</span>
      </v-col>

      <v-col cols="6" class="text-right">
        <v-btn
          :to="{ name: 'TaskView', params: { id: task.id } }"
          target="_blank"
          class="new-link"
          rounded
        >
          詳細
          <v-icon right color="#444444" size="20">
            $newTab
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <h3 class="mt-4 event-detail-customer">{{ task.customer.name }} 様</h3>
    <h4 class="mt-4 event-detail-customer">
      {{ task.site.name }}
    </h4>

    <div class="mb-3 event-detail-time">
      <div
        @click="openAddressInGoogleMaps()"
        style="cursor: pointer"
        class="py-1"
      >
        <v-icon size="20" class="mr-1">$location</v-icon>
        <span class="address-txt">
          {{ siteAddress }}
        </span>
        <v-icon size="18" class="ml-2">$newTab</v-icon>
      </div>
      <div class="py-1">
        <v-icon size="20" class="mr-1">$workTime</v-icon>
        <span class="meeting-txt">集合</span>
        <span class="ml-3 event-detail-time-text">
          {{ task.gathering_time }}
        </span>
      </div>
      <div class="py-1">
        <v-icon size="20" class="mr-1">$workTime</v-icon>
        <span class="start-txt">開始</span>
        <span class="ml-3 event-detail-time-text">
          {{ task.task_start_time }}
        </span>
      </div>
      <div v-if="task.car" class="py-1">
        <v-icon size="20" class="mr-1">$car</v-icon>
        <span class="car-info">
          {{ task.car.car_class }}  {{ task.car.number_plate }}
        </span>
      </div>
    </div>

    <div v-if="hasPartnerData" class="info-box pa-3 mb-6">
      <v-row dense align="center">
        <template v-if="project.partner_0">
          <v-col cols="5">
            <v-chip
              class="label d-block text-center"
              x-small
              label
              text-color="white"
              color="#666666"
            >
              メーカー
            </v-chip>
          </v-col>
          <v-col cols="7">
            <span class="font-weight-medium info-txt">
              {{ project.partner_0.name || '-' }}
            </span>
          </v-col>
        </template>
        <template v-if="project.partner_1">
          <v-col cols="5">
            <v-chip
              class="label d-block text-center"
              x-small
              label
              text-color="white"
              color="#666666"
            >
              発注
            </v-chip>
          </v-col>
          <v-col cols="7">
            <span class="font-weight-medium info-txt">
              {{ project.partner_1.name || '-' }}
            </span>
          </v-col>
        </template>

        <template v-if="project.partner_2">
          <v-col cols="5">
            <v-chip
              class="label d-block text-center"
              x-small
              label
              text-color="white"
              color="#666666"
            >
              元請
            </v-chip>
          </v-col>
          <v-col cols="7">
            <span class="font-weight-medium info-txt">
              {{ project.partner_2.name || '-' }}
            </span>
          </v-col>
        </template>
      </v-row>
    </div>

    <v-divider></v-divider>

    <v-row no-gutters class="mt-6 mb-3">
      <v-col cols="12" class="event-detail-title d-flex align-center">
        <v-icon color="#3C346E" size="25">
          $users
        </v-icon>
        <div class="ml-2">
          参加者<span class="ml-2 people">{{ task.task_member.length }}人</span>
        </div>
      </v-col>
    </v-row>

    <div class="mb-4">
      <member-list
        v-for="(member, memberIndex) in task.task_member"
        :key="`member-${memberIndex + 1}`"
        :name="member.full_name"
        :leader="isLeader(member)"
        :photo="member.profile_image_url"
      />
    </div>

    <v-divider></v-divider>

    <v-row no-gutters class="mt-6 mb-4">
      <v-col cols="12" class="event-detail-title d-flex align-center">
        <v-icon color="#3C346E" size="25">
          $work
        </v-icon>
        <span class="ml-2">内容</span>
      </v-col>
    </v-row>

    <div class="event-detail-task-title mb-4 font-weight-medium">
      {{ task.service.name }}
    </div>

    <template v-if="hasMemo">
      <v-divider></v-divider>
      <v-row no-gutters class="mt-6 mb-3">
        <v-col cols="12" class="event-detail-title">
          <v-icon color="#3C346E" size="25">
            $info
          </v-icon>
          メモ
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="mb-2">
          <v-chip
            text-color="white"
            color="#B00101"
            label
            small
            class="event-detail-warning-label mb-2"
          >
            重要連絡
          </v-chip>
          <div class="event-detail-warning-box">
            <div class="mb-4">
              {{ task.important_notice ? task.important_notice : '-' }}
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          通常メモ
          <div class="event-detail-memo-box mt-2">
            <div class="mb-4">
              {{ task.memo ? task.memo : '-' }}
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import MemberList from '@/components/admin/partials/Calendar/Event/Member/DetailList'
export default {
  name: 'EventDetail',
  components: { MemberList },
  computed: {
    task() {
      return this.$store.getters.task
    },
    project() {
      return this.task.site.projects.find(
        project => project.id === this.task.project.id
      )
    },
    hasPartnerData() {
      return (
        this.project.partner_0 ||
        this.project.partner_1 ||
        this.project.partner_2
      )
    },
    hasMemo() {
      return this.task.memo || this.task.important_notice
    },
    siteAddress() {
      if (
        !this.task.site.prefecture?.name &&
        !this.task.site.city &&
        !this.task.site.address
      )
        return '-'
      return `${this.task.site.prefecture?.name || ''}${this.task.site.city ||
        ''}${this.task.site.address || ''}`
    }
  },
  methods: {
    isLeader(member) {
      return this.task.leader?.id === member.id
    },
    openAddressInGoogleMaps() {
      window.open(
        `https://maps.google.com/?q=${encodeURIComponent(this.siteAddress)}`,
        '_blank'
      )
    }
  }
}
</script>

<style src="./Detail.scss" lang="scss" scoped></style>
