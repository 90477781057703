var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm.leader ? _c('div', {
    staticClass: "leader-label text-center"
  }, [_vm._v(" リ ")]) : _vm._e(), _c('v-avatar', {
    staticClass: "member-avatar",
    class: _vm.leader ? 'member-avatar-leader' : '',
    attrs: {
      "size": "40"
    }
  }, [_c('img', {
    attrs: {
      "alt": "avatar",
      "src": _vm.avatar
    }
  })])], 1)]), _c('v-col', {
    class: _vm.leader ? 'mt-2' : '',
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "member-name pl-2 font-weight-medium gray--text"
  }, [_vm._v(" " + _vm._s(_vm.name) + " "), _vm.leader ? [_vm._v(" （リーダー） ")] : _vm._e()], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }