<template>
  <v-row dense align="center">
    <v-col cols="auto">
      <div style="position:relative;">
        <div v-if="leader" class="leader-label text-center">
          リ
        </div>
        <v-avatar
          class="member-avatar"
          :class="leader ? 'member-avatar-leader' : ''"
          size="40"
        >
          <img alt="avatar" :src="avatar" />
        </v-avatar>
      </div>
    </v-col>

    <v-col cols="auto" :class="leader ? 'mt-2' : ''">
      <div class="member-name pl-2 font-weight-medium gray--text">
        {{ name }}
        <template v-if="leader">
          （リーダー）
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DetailList',
  props: {
    leader: {
      type: Boolean,
      default: false,
      required: false
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    photo: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    avatar() {
      return this.photo || require('@/assets/images/user-placeholder.png')
    }
  }
}
</script>

<style src="./DetailList.scss" lang="scss" scoped></style>
